// commented out midi file with duration longer than 200s
// TODO: uncomment after finishing 'Edit' feature
const MIDI_EXAMPLES = {
'alb_esp1.mid':'alb_esp1(Espana Op. 165)',
'alb_esp2.mid':'alb_esp2(Espana Op. 165)',
'alb_esp3.mid':'alb_esp3(Espana Op. 165)',
'alb_esp4.mid':'alb_esp4(Espana Op. 165)',
'alb_esp5.mid':'alb_esp5(Espana Op. 165)',
'alb_esp6.mid':'alb_esp6(Espana Op. 165)',
'alb_se1.mid':'alb_se1(Suite espagnole)',
'alb_se2.mid':'alb_se2(Suite espagnole)',
// 'alb_se3.mid':'alb_se3(Suite espagnole)',
'alb_se4.mid':'alb_se4(Suite espagnole)',
// 'alb_se5.mid':'alb_se5(Suite espagnole)',
// 'alb_se6.mid':'alb_se6(Suite espagnole)',
'alb_se7.mid':'alb_se7(Suite espagnole)',
// 'alb_se8.mid':'alb_se8(Suite espagnole)',
// 'appass_1.mid':'appass_1(Sonate Nr. 23 Op. 57)',
'appass_2.mid':'appass_2(Sonate Nr. 23 Op. 57)',
// 'appass_3.mid':'appass_3(Sonate Nr. 23 Op. 57)',
'bach_846.mid':'bach_846(Das wohltemperierte Klavier I - Praeludium und Fuge 1 in C-Dur BWV 846)',
'bach_847.mid':'bach_847(Das wohltemperierte Klavier I - Praeludium und Fuge 2 in c-Moll BWV 847)',
'bach_850.mid':'bach_850(Pr\u00e4ludium und Fuge in D-Dur, BWV 850)',
// 'beethoven_hammerklavier_1.mid':'beethoven_hammerklavier_1(Hammerklaviersonate 1. Satz)',
'beethoven_hammerklavier_2.mid':'beethoven_hammerklavier_2(Hammerklaviersonate 1. Satz - 2. Satz)',
// 'beethoven_hammerklavier_3.mid':'beethoven_hammerklavier_3(Hammerklaviersonate: 3. Satz)',
// 'beethoven_hammerklavier_4.mid':'beethoven_hammerklavier_4(Hammerklaviersonate 4. Satz)',
// 'beethoven_les_adieux_1.mid':'beethoven_les_adieux_1(Sonate Les adieux 1. Satz)',
// 'beethoven_les_adieux_3.mid':'beethoven_les_adieux_3(Sonate Les adieux 3. Satz)',
// 'beethoven_opus10_1.mid':'beethoven_opus10_1(Klaviersonate No. 5 -  1. Satz)',
'beethoven_opus10_2.mid':'beethoven_opus10_2(Sonate Op. 10 No. 1 2. Satz)',
// 'beethoven_opus10_3.mid':'beethoven_opus10_3(Sonate Opus 10 No. 1 3. Satz)',
// 'beethoven_opus22_1.mid':'beethoven_opus22_1(Grande Sonata Opus 22 1. movement)',
'beethoven_opus22_2.mid':'beethoven_opus22_2(Grande Sonata Opus 22 2. movement)',
'beethoven_opus22_3.mid':'beethoven_opus22_3(Grande Sonata Opus 22 3. movement)',
// 'beethoven_opus22_4.mid':'beethoven_opus22_4(Grande Sonate Opus 22 - 4. movement)',
// 'bk_xmas1.mid':'bk_xmas1(Fantasie \u00fcber "Stille Nacht, heilige Nacht")',
// 'bk_xmas2.mid':'bk_xmas2(Weihnachtsfantasie)',
// 'bk_xmas3.mid':'bk_xmas3(Weihnachts-Fantasie)',
'bk_xmas4.mid':'bk_xmas4(Christfreuden - Weihnachtsfantasie)',
// 'bk_xmas5.mid':'bk_xmas5(Zu Weihnachten - Fantasie)',
'bor_ps1.mid':'bor_ps1(Petite Suite)',
'bor_ps2.mid':'bor_ps2(Petite Suite)',
'bor_ps3.mid':'bor_ps3(Petite Suite)',
'bor_ps4.mid':'bor_ps4(Petite Suite)',
'bor_ps5.mid':'bor_ps5(Petite Suite)',
'bor_ps6.mid':'bor_ps6(Petite Suite)',
'bor_ps7.mid':'bor_ps7(Petite Suite)',
'brahms_opus117_1.mid':'brahms_opus117_1(Intermezzo Opus 117 Nr. 1)',
// 'brahms_opus1_1.mid':'brahms_opus1_1(Sonate in C Opus 1)',
'brahms_opus1_2.mid':'brahms_opus1_2(Sonate in C 2. Satz)',
// 'brahms_opus1_3.mid':'brahms_opus1_3(: Sonate in C - 3. Satz)',
// 'brahms_opus1_4.mid':'brahms_opus1_4(Sonate in C - 4. Satz)',
'br_im2.mid':"br_im2(Pan's auf Standard am 18.1.98)",
'br_im5.mid':'br_im5(Intermezzo Nr. 5, Opus 116)',
'br_im6.mid':'br_im6(Fantasie Nr. 6, Opus 116)',
// 'br_rhap.mid':'br_rhap(Rhapsodie Nr. 4 in Es-Dur, Op. 119)',
'burg_gewitter.mid':'burg_gewitter(Etueden Opus 109)',
'burg_perlen.mid':'burg_perlen(Etueden Opus 109)',
'burg_quelle.mid':'burg_quelle(Etueden Opus 109)',
'chpn-e01.mid':'chpn-e01(Etude Opus 10 No. 5)',
'chpn-e11.mid':'chpn-e11(Etude Opus 25, No. 11)',
'chpn-e12.mid':'chpn-e12(Opus 10 Nr. 12 Es-dur)',
'chpn-p1.mid':'chpn-p1(Chopin Prelude  No. 1, Opus 28)',
'chpn-p10.mid':'chpn-p10(Prelude No. 10)',
'chpn-p11.mid':'chpn-p11(Prelude No. 11)',
'chpn-p12.mid':'chpn-p12(Chopin Prelude  No. 12, Opus 28)',
'chpn-p13.mid':'chpn-p13(Chopin Prelude  No. 13, Opus 28)',
'chpn-p14.mid':'chpn-p14(Chopin Prelude  No. 14, Opus 28)',
'chpn-p15.mid':'chpn-p15(Chopin Prelude  No. 15, Opus 28)',
'chpn-p16.mid':'chpn-p16(Chopin Prelude  No. 16, Opus 28)',
'chpn-p17.mid':'chpn-p17(Chopin Prelude  No. 17, Opus 28)',
'chpn-p18.mid':'chpn-p18(Chopin Prelude  No. 18, Opus 28)',
'chpn-p19.mid':'chpn-p19(Chopin Prelude  No. 19, Opus 28)',
'chpn-p2.mid':'chpn-p2(Chopin Prelude  No. 2, Opus 28)',
'chpn-p20.mid':'chpn-p20(Chopin Prelude  No. 20, Opus 28)',
'chpn-p21.mid':'chpn-p21(Chopin Prelude  No. 21, Opus 28)',
'chpn-p22.mid':'chpn-p22(Chopin Prelude  No. 22, Opus 28)',
'chpn-p23.mid':'chpn-p23(Chopin Prelude  No. 23, Opus 28)',
'chpn-p24.mid':'chpn-p24(Chopin Prelude  No. 24, Opus 28)',
'chpn-p3.mid':'chpn-p3(Chopin Prelude  No. 3, Opus 28)',
'chpn-p4.mid':'chpn-p4(Chopin Prelude  No. 4, Opus 28)',
'chpn-p5.mid':'chpn-p5(Chopin Prelude  No. 5, Opus 28)',
'chpn-p6.mid':'chpn-p6(Chopin Prelude  No. 6, Opus 28)',
'chpn-p7.mid':'chpn-p7(Chopin Prelude No. 7)',
'chpn-p8.mid':'chpn-p8(Prelude No. 8)',
'chpn-p9.mid':'chpn-p9(Chopin Prelude No. 9)',
'chpn_op10_e01.mid':'chpn_op10_e01(Etude Opus 10 No. 5)',
'chpn_op10_e05.mid':'chpn_op10_e05(Etude Opus 10 No. 5)',
'chpn_op10_e12.mid':'chpn_op10_e12(Opus 10 Nr. 12 Es-dur)',
// 'chpn_op23.mid':'chpn_op23(Chopin Ballade in G minor Opus 32)',
// 'chpn_op25_e1.mid':'chpn_op25_e1(Chopin Etude  No. 1, Opus 25)',
'chpn_op25_e11.mid':'chpn_op25_e11(Etude Opus 25, No. 11)',
'chpn_op25_e2.mid':'chpn_op25_e2(Chopin Etude  No. 2, Opus 25)',
'chpn_op25_e3.mid':'chpn_op25_e3(Chopin Etude  No. 3, Opus 25)',
'chpn_op25_e4.mid':'chpn_op25_e4(Chopin Etude  No. 4, Opus 25)',
'chpn_op27_1.mid':'chpn_op27_1(Chopin Nocturne Opus 27 Nr. 1)',
'chpn_op27_2.mid':'chpn_op27_2(Chopin Nocturne Opus 27 Nr. 2)',
'chpn_op33_.mid':'chpn_op33_(Chopin Nocturne Opus 33 Nr. 4)',
'chpn_op33_2.mid':'chpn_op33_2(Chopin Mazurka Opus 33 Nr. 2)',
'chpn_op33_4.mid':'chpn_op33_4(Chopin Nocturne Opus 33 Nr. 4)',
// 'chpn_op35_1.mid':'chpn_op35_1(Sonate opus 35, 1. Satz)',
// 'chpn_op35_2.mid':'chpn_op35_2(Sonate opus 35, 2. Satz)',
// 'chpn_op35_3.mid':'chpn_op35_3(Sonate opus 35, 3. Satz)',
'chpn_op35_4.mid':'chpn_op35_4(Sonate opus 35, 4. Satz)',
// 'chpn_op53.mid':'chpn_op53(Chopin Polonaise in Ab major Opus 53)',
// 'chpn_op66.mid':'chpn_op66(Chopin Fantasie-Impromptu in C# minor Opus 66)',
'chpn_op7_1.mid':'chpn_op7_1(Chopin Mazurka Opus 7 No. 1)',
'chpn_op7_2.mid':'chpn_op7_2(Chopin Mazurka Opus 7 No. 2)',
// 'chp_op18.mid':'chp_op18(Grand Valse Brillante in Es-Dur Opus 18)',
// 'chp_op31.mid':'chp_op31(Scherzo  in b-moll Opus 31)',
'deb_clai.mid':'deb_clai(Suite bergamasque)',
'deb_menu.mid':'deb_menu(Suite bergamasque)',
'deb_pass.mid':'deb_pass(Suite bergamasque)',
'deb_prel.mid':'deb_prel(Suite bergamasque)',
'elise.mid':'elise(F\u00fcr Elise)',
'god_alb_esp2.mid':'god_alb_esp2(Espana Op. 165)',
'god_chpn_op10_e01.mid':'god_chpn_op10_e01(Etude Opus 10 No. 5)',
'gra_esp_2.mid':'gra_esp_2(Danza Espanola No. 2)',
'gra_esp_3.mid':'gra_esp_3(Danza Espanola No. 3)',
'gra_esp_4.mid':'gra_esp_4(Danza Espanola No. 4)',
'grieg_butterfly.mid':'grieg_butterfly(Grieg: Lyrische St\u00fccke Op. 43 No. 1 - Schmetterling)',
'grieg_elfentanz.mid':'grieg_elfentanz(Grieg: Lyrische St\u00fccke Op. 12 Nr. 4 Elfentanz)',
'grieg_halling.mid':'grieg_halling(Grieg: Lyrische St\u00fccke Book II Opus 38 Nr. 4 - Halling)',
'grieg_kobold.mid':'grieg_kobold(Kobold Op 71, No. 3)',
'grieg_voeglein.mid':'grieg_voeglein(V\u00f6glein Op 43, No. 4)',
'grieg_waechter.mid':'grieg_waechter(Wachterlied Op12, No. 3)',
'grieg_walzer.mid':'grieg_walzer(Lyric Pieces Op. 12 No. 12 Valse)',
'grieg_wanderer.mid':'grieg_wanderer(Grieg: Lyrische St\u00fccke Op. 43 Nr. 2 - Einsamer Wanderer)',
// 'grieg_wedding.mid':'grieg_wedding(Hochzeitstag auf Troldhaugen Op 65, No. 6)',
'grieg_zwerge.mid':'grieg_zwerge(Zug der Zwerge Op 54, No. 3)',
// 'haydn_35_1.mid':'haydn_35_1(Sonate XVI:35 1. Satz)',
'haydn_35_2.mid':'haydn_35_2(Sonate XVI:35 1. Satz)',
'haydn_35_3.mid':'haydn_35_3(Sonate XVI:35 3. Satz)',
// 'hay_40_1.mid':'hay_40_1(Klaviersonate in G-Dur Hoboken XVI:40)',
'hay_40_2.mid':'hay_40_2(Klaviersonate in G-Dur Hoboken XVI:40)',
// 'islamei.mid':'islamei(Islamei)',
// 'liz_donjuan.mid':'liz_donjuan(Reminiscnenes do Don Juan)',
// 'liz_et1.mid':'liz_et1(Etude No. 1 aus Grandes Etudes de Paganini)',
// 'liz_et2.mid':'liz_et2(Etude No. 2 aus Grandes Etudes de Paganini)',
// 'liz_et3.mid':'liz_et3(Etude No. 3  aus Grandes Etudes de Paganini)',
'liz_et4.mid':'liz_et4(Etude No. 4 aus Grandes Etudes de Paganini)',
'liz_et5.mid':'liz_et5(Etude No. 5 aus Grandes Etudes de Paganini)',
// 'liz_et6.mid':'liz_et6(Etude No. 6 aus Grandes Etudes de Paganini)',
// 'liz_et_trans4.mid':"liz_et_trans4(Etudes d'execution transcendante)",
// 'liz_et_trans5.mid':"liz_et_trans5(Franz Liszt: Etudes d'execution transcendante No. 5)",
// 'liz_et_trans8.mid':"liz_et_trans8(Etudes d'execution transcendante No. 8)",
'liz_liebestraum.mid':'liz_liebestraum(Liebestraum Nr. 3)',
// 'liz_rhap02.mid':'liz_rhap02(Ungarische Rhapsodie Nr. 2)',
// 'liz_rhap09.mid':'liz_rhap09(Rhapsodie Nr. 9 - Pesther Karneval)',
// 'liz_rhap10.mid':'liz_rhap10(Rhapsodie Nr. 10)',
// 'liz_rhap12.mid':'liz_rhap12(Rhapsodie Nr. 12)',
// 'liz_rhap15.mid':'liz_rhap15(Rhapsodie Nr. 15)',
'mendel_op30_1.mid':'mendel_op30_1(Lieder ohne Worte Op. 30 No. 1)',
'mendel_op53_5.mid':'mendel_op53_5(Lieder ohne Worte Op. 53 No. 5)',
'mendel_op62_3.mid':'mendel_op62_3(Lieder ohne Worte Op. 62 No. 3)',
'mendel_op62_4.mid':'mendel_op62_4(Lieder ohne Worte Op. 62 No. 4)',
'mendel_op62_5.mid':'mendel_op62_5(Lieder ohne Worte Op. 62 No. 5)',
'mond_1.mid':'mond_1(Sonata No.14 in cis-Moll, Op. 27/2 - 1. Satz)',
'mond_2.mid':'mond_2(Sonata No.14 in cis-Moll, Op. 27/2 - 2. Satz)',
// 'mond_3.mid':'mond_3(Sonata No.14 in cis-Moll, Op. 27/2 - 3. Satz)',
// 'mos_op36_6.mid':'mos_op36_6(Etincelles Opus 36, No. 6)',
'muss_1.mid':'muss_1(Bilder einer Ausstellung)',
'muss_2.mid':'muss_2(Bilder einer Ausstellung)',
'muss_3.mid':'muss_3(Bilder einer Ausstellung)',
'muss_4.mid':'muss_4(Bilder einer Ausstellung)',
'muss_5.mid':'muss_5(Bilder einer Ausstellung)',
'muss_6.mid':'muss_6(Bilder einer Ausstellung)',
// 'muss_7.mid':'muss_7(Bilder einer Ausstellung)',
// 'muss_8.mid':'muss_8(Bilder einer Ausstellung)',
// 'mz_311_1.mid':'mz_311_1(Klaviersonate Nr. 12 KV 331 1. Satz)',
'mz_311_2.mid':'mz_311_2(Klaviersonate Nr. 12 KV 331 1. Satz)',
// 'mz_311_3.mid':'mz_311_3(Klaviersonate Nr. 12 KV 331 3. Satz)',
// 'mz_330_1.mid':'mz_330_1(Klaviersonate C-Dur KV 330)',
'mz_330_2.mid':'mz_330_2(Klaviersonate C-Dur KV 330)',
// 'mz_330_3.mid':'mz_330_3(Klaviersonate C-Dur KV 330)',
// 'mz_331_1.mid':'mz_331_1(Klaviersonate Nr. 11 KV 331)',
// 'mz_331_2.mid':'mz_331_2(Klaviersonate Nr. 11 KV 331 2. Satz)',
// 'mz_331_3.mid':'mz_331_3(Klaviersonate Nr. 11 KV 331 3. Satz)',
// 'mz_332_1.mid':'mz_332_1(Klaviersonate Nr. 12 KV 332 1. Satz)',
'mz_332_2.mid':'mz_332_2(Klaviersonate Nr. 12 KV 332 2. Satz)',
// 'mz_332_3.mid':'mz_332_3(Klaviersonate Nr. 12 KV 332 No. 3)',
// 'mz_333_1.mid':'mz_333_1(Klaviersonate B-Dur KV 333)',
// 'mz_333_2.mid':'mz_333_2(Klaviersonate B-Dur KV 333)',
// 'mz_333_3.mid':'mz_333_3(Klaviersonate B-Dur KV 333)',
// 'mz_545_1.mid':'mz_545_1(Sonate KV 545 1. Satz)',
'mz_545_2.mid':'mz_545_2(Klaviersonate KV 545 2. Satz)',
'mz_545_3.mid':'mz_545_3(Klaviersonate KV 545 3. Satz)',
// 'mz_570_1.mid':'mz_570_1(Klaviersonate KV 570 1. Satz)',
'mz_570_2.mid':'mz_570_2(Klaviersonate KV 570 2.. Satz)',
'mz_570_3.mid':'mz_570_3(Sonate KV 570 3. Satz)',
// 'pathetique_1.mid':'pathetique_1(Beethoven Sonata No. 8 in C minor "Pathetique")',
'pathetique_2.mid':'pathetique_2(Beethoven Sonata No. 8 in C minor "Pathetique")',
// 'pathetique_3.mid':'pathetique_3(Beethoven Sonata No. 8 in C minor "Pathetique")',
// 'schubert_D850_1.mid':'schubert_D850_1(Sonata D850, 1. movement)',
// 'schubert_D850_2.mid':'schubert_D850_2(Sonata D850, 2. movement)',
// 'schubert_D850_3.mid':'schubert_D850_3(Schubert: Sonata D850, 3. movement)',
// 'schubert_D850_4.mid':'schubert_D850_4(Sonata D850, 4. movement)',
// 'schubert_D935_1.mid':'schubert_D935_1(Impromptu D 935 No. 1)',
// 'schubert_D935_2.mid':'schubert_D935_2(Vier Impromptus Opus postb. 142 D 935)',
// 'schubert_D935_3.mid':'schubert_D935_3(Impromptu D 935 No. 3)',
// 'schubert_D935_4.mid':'schubert_D935_4(Impromptu D 935 Nr. 4)',
// 'schub_d760_1.mid':'schub_d760_1(Wandererfantasie D760, 1. Satz)',
// 'schub_d760_2.mid':'schub_d760_2(Wandererfantasie D760, 2. Satz)',
// 'schub_d760_3.mid':'schub_d760_3(Wandererfantasie D760, 3 Satz)',
// 'schub_d760_4.mid':'schub_d760_4(Wandererfantasie D760, 4 Satz)',
// 'schub_d960_1.mid':'schub_d960_1(Sonate D960, 1. Satz)',
'schub_d960_2.mid':'schub_d960_2(Sonate D960, 2. Satz)',
// 'schub_d960_3.mid':'schub_d960_3(Sonate D960, 3. Satz)',
// 'schub_d960_4.mid':'schub_d960_4(Sonate D960, 4. Satz)',
// 'schuim-1.mid':'schuim-1(Vier Impromptus Opus 90 D 899)',
// 'schuim-2.mid':'schuim-2(Vier Impromptus Opus 90 D 899)',
// 'schuim-3.mid':'schuim-3(Vier Impromptus Opus 90 D 899)',
// 'schuim-4.mid':'schuim-4(Vier Impromptus Opus 90 - D 899)',
// 'schumm-1.mid':'schumm-1(Sechs Moments Musicaux Opus 94 D 780)',
'schumm-2.mid':'schumm-2(Sechs Moments Musicaux Opus 94 D 780)',
'schumm-3.mid':'schumm-3(Sechs Moments Musicaux Opus 94 D 780)',
// 'schumm-4.mid':'schumm-4(Sechs Moments Musicaux Opus 94 D 780)',
'schumm-5.mid':'schumm-5(Sechs Moments Musicaux Opus 94 D 780)',
'schumm-6.mid':'schumm-6(Schubert: Moment Musical No.6 in Ab, Op. 94 - D 780)',
// 'schum_abegg.mid':'schum_abegg(Abegg-Variationen)',
// 'schu_143_1.mid':'schu_143_1(Klaviersonate a-Moll, Opus 143)',
'schu_143_2.mid':'schu_143_2(Klaviersonate a-Moll, Opus 143 2. Satz)',
// 'schu_143_3.mid':'schu_143_3(Klaviersonate a-Moll, Opus 143 3. Satz)',
'scn15_1.mid':'scn15_1(Kinderszenen Opus 15)',
'scn15_10.mid':'scn15_10(Kinderszenen Opus 15)',
'scn15_11.mid':'scn15_11(Kinderszenen Opus 15)',
'scn15_12.mid':'scn15_12(Kinderszenen Opus 15)',
'scn15_13.mid':'scn15_13(Kinderszenen Opus 15)',
'scn15_2.mid':'scn15_2(Kinderszenen Opus 15)',
'scn15_3.mid':'scn15_3(Kinderszenen Opus 15)',
'scn15_4.mid':'scn15_4(Kinderszenen Opus 15)',
'scn15_5.mid':'scn15_5(Kinderszenen Opus 15)',
'scn15_6.mid':'scn15_6(Kinderszenen Opus 15)',
'scn15_7.mid':'scn15_7(Kinderszenen Opus 15)',
'scn15_8.mid':'scn15_8(Kinderszenen Opus 15)',
'scn15_9.mid':'scn15_9(Kinderszenen Opus 15)',
'scn16_1.mid':'scn16_1(Kreisleriana Nr. 1)',
// 'scn16_2.mid':'scn16_2(Kreisleriana Nr. 2)',
// 'scn16_3.mid':'scn16_3(Kreisleriana 3)',
'scn16_4.mid':'scn16_4(Kreisleriana No. 4)',
'scn16_5.mid':'scn16_5(Kreisleriana Teil 5)',
'scn16_6.mid':'scn16_6(Kreisleriana)',
'scn16_7.mid':'scn16_7(Kreisleriana No. 7)',
'scn16_8.mid':'scn16_8(Kreisleriana Teil 8)',
'scn68_10.mid':'scn68_10(Schumann: Fr\u00f6hlicher Landmann, von der Arbeit zur\u00fcckkehrend)',
'scn68_12.mid':'scn68_12(Schumann: Knecht Ruprecht aus  Album f\u00fcr die Jugend Opus 68)',
'ty_april.mid':'ty_april(Die Jahreszeiten)',
// 'ty_august.mid':'ty_august(Die Jahreszeiten)',
// 'ty_dezember.mid':'ty_dezember(Die Jahreszeiten)',
'ty_februar.mid':'ty_februar(Die Jahreszeiten)',
'ty_januar.mid':'ty_januar(Die Jahreszeiten)',
'ty_juli.mid':'ty_juli(Die Jahreszeiten)',
'ty_juni.mid':'ty_juni(Die Jahreszeiten)',
'ty_maerz.mid':'ty_maerz(Die Jahreszeiten)',
'ty_mai.mid':'ty_mai(Die Jahreszeiten)',
'ty_november.mid':'ty_november(Die Jahreszeiten)',
'ty_oktober.mid':'ty_oktober(Die Jahreszeiten)',
'ty_september.mid':'ty_september(Die Jahreszeiten)',
// 'waldstein_1.mid':'waldstein_1(Beethoven Sonata No. 21 in C major)',
'waldstein_2.mid':'waldstein_2(Beethoven Sonata No. 21 in C major)',
// 'waldstein_3.mid':'waldstein_3(Beethoven Sonata No. 21 in C major)',
};

export default MIDI_EXAMPLES;